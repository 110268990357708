import React from 'react';

import Box from '@mui/material/Box';

import HeroImageWoman from '../../images/hero-image-woman.png';

const HeroImageShort = (props) => {
  const {
    imageUrl = HeroImageWoman,        // default hero image
    sx,
  } = props;

  return (
    <Box
      id="bendable-labs-hero-wrapper"
      sx={{
        width: '100%',
        height: { xs: '270px', sm: '400px', md: '470px', lg: '320px', xl: '360px' },
        background: `url(${imageUrl}) no-repeat center center`,
        backgroundSize: 'cover',
        ...sx
      }}
    >
      &nbsp;
    </Box>
  );
};

export default HeroImageShort;
