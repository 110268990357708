import React, { useContext } from 'react';
import _ from 'lodash-es';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ShopImage from './ShopImage';


const BetterWayToShare = (props) => {
  const {
    sx,
  } = props;

  return (
    <Grid
      container={true}
      id="hero-block-1"
      sx={{
        width: '100%',
        marginTop: { xs: '20px', sm: '30px', md: '40px', lg: '60px', xl: '60px' },
      }}
    >
      <Grid item={true} xs={12} lg={6}
        sx={{
          width: { xs: '100%', md: '100%' },
          background: '#ffffff',
          color: '#1B7392',
          paddingTop: { xs: '20px', sm: '30px', md: '40px', lg: '40px', xl: '40px' },
          paddingLeft: { xs: '20px', sm: '30px', md: '60px', lg: '80px', xl: '80px' },
          paddingBottom: { xs: '20px', sm: '30px', md: '40px', lg: '80px', xl: '80px' },
          paddingRight: { xs: '20px', sm: '30px', md: '60px', lg: '60px', xl: '60px' },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Cooper Std',
            fontSize: { xs: '36px', sm: '46px', md: '46px', lg: '54px', xl: '54px' },
            lineHeight: { xs: '36px', sm: '46px', md: '46px', lg: '58px', xl: '58px' },
            marginBottom: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '20px' },
          }}
        >
          A better way to ‘shop’
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: { xs: '20px', sm: '24px', md: '28px', lg: '34px', xl: '34px' },
            lineHeight: { xs: '22px', sm: '26px', md: '32px', lg: '38px', xl: '38px' },
            color: '#000000',
            marginBottom: { xs: '20px', sm: '30px', md: '40px', lg: '40px', xl: '40px' },
          }}
        >
          For the people you serve, every Bendable resource is searchable, browsable and likable.<br/>
          <br/>
          Users interact with Bendable like a shopping app, easily discovering, connecting to and saving the resources they need.
        </Typography>
      </Grid>

      <Grid item={true} xs={12} lg={6}
        sx={{
          width: { xs: '100%', md: '100%' },
        }}
      >
        <ShopImage />
      </Grid>

    </Grid>  
  )
};

export default BetterWayToShare;