import React from 'react';

import Box from '@mui/material/Box';

import HeroImageWoman from '../../images/better_way_to_show.png';

const ShowImage = (props) => {
  const {
    imageUrl = HeroImageWoman,        // default hero image
    sx,
  } = props;

  return (
    <Box
      id="share-image"
      sx={{
        width: '100%',
        height: { xs: '380px', sm: '380px', md: '380px', lg: '380px', xl: '500px' },
        background: `url(${imageUrl}) no-repeat center center`,
        backgroundSize: 'contain',
        ...sx
      }}
    >
      &nbsp;
    </Box>
  );
};

export default ShowImage;
