import React, { useContext } from 'react';
import _ from 'lodash-es';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HeroImageShort from './HeroImageShort';
// import bendableBackground from '../../images/Bendable-Primary-Background-Pattern-2.png';
import bendableBackground from '../../images/Bendable-Primary-Background-Pattern-3.png';


const HeroBlock1 = (props) => {
  const {
    heroImageUrl,
    sx,
  } = props;

  const theme = useTheme();
  const isLessThanMd = useMediaQuery(theme.breakpoints.down('md'));     // allows for styling differences for XS and SM devices

  return (
    <Grid
      container={true}
      id="hero-block-1"
      sx={{
        width: '100%',
        backgroundImage: isLessThanMd ? undefined : `url(${bendableBackground})`,
        backgroundSize: isLessThanMd ? undefined : 'cover',
      }}
    >
      <Grid item={true} xs={12} lg={6}
        sx={{
          width: { xs: '100%', md: '100%' },
          paddingTop: { xs: '20px', sm: '30px', md: '40px', lg: '80px', xl: '120px' },
          paddingLeft: { xs: '20px', sm: '30px', md: '60px', lg: '60px', xl: '120px' },
          paddingBottom: { xs: '20px', sm: '30px', md: '40px', lg: '80px', xl: '80px' },
          paddingRight: { xs: '20px', sm: '30px', md: '60px', lg: '60px', xl: '120px' },
        }}
      >
        <Typography
          sx={{
            width: { xs: '100%', sm: '100%', md: '50%', lg: '100%', xl: '100%' },
            fontFamily: 'Cooper Std',
            fontSize: { xs: '36px', sm: '46px', md: '46px', lg: '54px', xl: '64px' },
            lineHeight: { xs: '36px', sm: '46px', md: '46px', lg: '52px', xl: '62px' },
            color: '#38718F',
            marginBottom: { xs: '0px', sm: '10px', md: '0px', lg: '40px', xl: '40px' },
          }}
        >
          What does your community need to know?
        </Typography>
      </Grid>      
      
      <Grid item={true} xs={12} lg={6}
        sx={{
          width: { xs: '100%', md: '100%' },
          paddingTop: { xs: '0px', sm: '0px', md: '0px', lg: '60px', xl: '60px' },
          paddingLeft: { xs: '20px', sm: '30px', md: '60px', lg: '60px', xl: '60px' },
          paddingBottom: { xs: '20px', sm: '30px', md: '40px', lg: '80px', xl: '80px' },
          paddingRight: { xs: '20px', sm: '30px', md: '60px', lg: '60px', xl: '60px' },          
        }}
      >
        { (heroImageUrl !== null) ?
        <HeroImageShort
          imageUrl={heroImageUrl}
        /> : null }
      </Grid> 

    </Grid>  
  )
};

export default HeroBlock1;