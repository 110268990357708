import React, {useState} from 'react';
import { Link, navigate } from "gatsby";
import ReactPlayer from 'react-player/lazy';
import Modal from 'react-modal';

import Box from '@mui/material/Box';
import Layout from '../components/layout';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import GenericButton from '../components/shared/genericButton';
import BendableHeroBG from '../components/librariesPage/bendableHeroBG';
import ContactFormImageBG from '../components/librariesPage/contactFormImageBG';
import ContactForm from '../components/librariesPage/contactForm';
import BendableLabsLogo from '../components/footer/BendableLabsLogo';

import BendableOnPhones from '../images/bendable-on-three-phones.png';
import FindYourBendable from '../components/findYourBendable/FindYourBendable';
import HeroBlock1 from '../components/heroBlocks/HeroBlock1';
import HeroBlock2 from '../components/heroBlocks/HeroBlock2';
import BetterWayToShare from '../components/betterWays/BetterWayToShare';
import BetterWayToShop from '../components/betterWays/BetterWayToShop';
import BetterWayToShow from '../components/betterWays/BetterWayToShow';
import ContactFormBlock from '../components/contactForm/ContactFormBlock';
import QuoteBlock from '../components/blocks/QuoteBlock';


Modal.setAppElement(`#___gatsby`);

const IndexPage = () => {
  const isLarge = useMediaQuery('(min-width:1200px)');

  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      zIndex: 20
    },
    content: {
      position: 'absolute',
      top: '40px',
      left: '40px',
      right: '40px',
      bottom: '40px',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '20px'
    },
    
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [libraryAccordionIsExpanded, setLibraryAccordionIsExpanded] = useState(false);

  function openModal() {
    console.log('openModal called...');
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    console.log('closeModal called...');
    setIsOpen(false);
  }

  const handleFindYourLibraryClick = (event) => {
    window.scrollTo(0, 0);
    setLibraryAccordionIsExpanded(true);
  };

  const handleShowLibrariesPageClick = (event) => {
    navigate('/libraries');
  };

  return (
  <Layout pageTitle="Bendable">
    
    <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Example Modal"
    >
      <svg onClick={closeModal} className="closeModal" fill="none" height="35" viewBox="0 0 35 35" 
        width="35" xmlns="http://www.w3.org/2000/svg"><circle cx="17.5" cy="17.5" fill="#ccc" 
        opacity=".8" r="17.5"/><g opacity=".75" stroke="#161d26" strokeLinecap="round" 
        strokeLinejoin="round" strokeWidth="3"><path d="m22 13-9 9"/><path d="m22 22-9-9"/></g>
      </svg>
      <ReactPlayer
        url="https://vimeo.com/568984658"
        width="90%"
        height="90%"
        config={{
          vimeo: {
            playerOptions: { 
              autoplay: true,
              controls: true
             }
          }
        }}
      />
    </Modal>

    <div
      id="page"
      className="Site"
      style={{
        // backgroundImage: "url(https://bendable.s3.us-west-1.amazonaws.com/marketing/homepage-background.png)"
      }}>

      <header id="masthead" className="Site-header">
        <div className="Site-branding">
          <a href="/" className="custom-logo-link" rel="home" aria-current="page"><img width="236" height="74" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/bendable-logo.svg" className="custom-logo" alt="Bendable" /></a>		
        </div>
        <Box flexGrow={1}></Box>
        <Box
          sx={{
            paddingRight: { xs: '0px', sm: '20px', md: '40px', lg: '40px', xl: '60px' }
          }}
        >
          <Link to="/sites">
            <GenericButton 
              text="Find your Bendable"
              color="#000000" 
              backgroundColor="#95FFEF" 
              borderColor="#95FFEF" 
              fontSize={{ xs: '12px', sm: '18px' }}
              height={{ xs: '28px', sm: '52px' }}
              paddingTop={{ xs: '12px', sm: '20px' }}
              paddingBottom={{ xs: '12px', sm: '20px' }}
              paddingLeft={{ xs: '12px', sm: '20px' }}
              paddingRight={{ xs: '12px', sm: '20px' }}
              borderRadius={{ xs: '14px', sm: '34px' }}
              // onClick={handleFindYourLibraryClick} 
            />
          </Link>
        </Box>
      </header>

      <main id="primary" className="Site-main">

        <FindYourBendable
          expanded={libraryAccordionIsExpanded}
          onClose={() => setLibraryAccordionIsExpanded(false)}
        />

        <HeroBlock1 heroImageUrl={null} />

        <HeroBlock2 />

        <BetterWayToShare />

        <BetterWayToShop />

        <BetterWayToShow />

        <QuoteBlock />

        <ContactFormBlock />

        {/* <article id="post-8" className="post-8 page type-page status-publish hentry">

          <div className="entry-content">

            <Box sx={{ paddingTop: {xs: '60px', lg: '120px'} }}>
              <div className="Masthead" >
                <div>
                  <h1 className="Masthead-title" style={{ marginBottom: '40px' }}>What do you want to learn?</h1>
                </div>

                <div className="Masthead-image dontshowModal" data-modal="dontmediaModal"><img onClick={openModal} width="1440" height="808" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x.png 1440w, https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x-300x168.png 300w, https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x-1024x575.png 1024w, https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x-768x431.png 768w" sizes="(max-width: 1440px) 100vw, 1440px" /></div>  
              </div>
            </Box>

            <Box 
              display="flex" 
              flexDirection={{ xs: 'column', lg: 'row' }} 
            >
              <Box 
                sx={{ 
                  width: { xs: '100%', lg: '40%'}, 
                  height: { xs: '300px', lg: '500px'} 
                }}
              >
                <BendableHeroBG />
              </Box>              
              <Box
                sx={{ 
                  padding: { xs: '60px 16px 30px 16px', sm: '60px 60px 30px 60px', lg: '152px 120px 60px 120px'}, 
                  width: { xs: '100%', lg: '60%'}, 
                  height: { xs: '350px', lg: '500px'},
                  backgroundColor: '#1B7392'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Cooper Std',
                    fontStyle: 'normal',
                    fontWeight: 900,
                    fontSize: { xs:'35px', lg: '54px' },
                    lineHeight: { xs:'40px', lg: '60px' },
                    letterSpacing: '-1.125px',
                    color: '#FFFFFF',
                    maxWidth: { xs:'320px', sm: '600px', md: '800px', xl: '800px' },
                    paddingBottom: { xs: '30px', lg: '40px'}, 
                  }}
                >
                  The place your community comes to learn
                </Typography>

                  <GenericButton
                    text="Find your Bendable"
                    color="#FFFFFF"
                    backgroundColor="#F4615F"
                    borderColor="#F4615F"
                    width={{ xs:'100%', lg: '350px'}}
                    height={{ xs:'48px', lg: '68px'}}
                    fontSize={{ xs:'18px', lg: '24px'}}
                    centerInParent={ isLarge ? false : true }
                    onClick={handleFindYourLibraryClick}
                  />

              </Box>
            </Box>

            <Box display="flex" flexDirection="column">
              <div className="Section flushLeft" style={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
                <div className="Section-content">
                  <h2>A community-centered learning marketplace</h2>
                  <p>Bendable is a digital platform that provides people with access to free learning content in areas that matter to them the most, including work skills, personal technology, financial literacy, well-being and effective parenting.</p>
                </div>
            
                <div className="Section-image has-mobile ">
                  <img width="1630" height="1337" src={BendableOnPhones} sizes="(max-width: 1630px) 100vw, 1630px" />
                </div>

                <div className="Section-image-mobile">
                  <img width="335" height="204" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Bendable-Phone-Mockups-mobile.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/Bendable-Phone-Mockups-mobile.png 335w, https://bendable.s3.us-west-1.amazonaws.com/marketing/Bendable-Phone-Mockups-mobile-300x183.png 300w" sizes="(max-width: 335px) 100vw, 335px" />
                </div>
              </div>

              <blockquote className="wp-block-quote" style={{ backgroundColor: '#F4615F' }}><p>“Bendable has been an important tool for our library to meet a need in our community for accessible and affordable continued learning outside of traditional education.”</p><cite><strong>&#8211; Norah Alwalan</strong> Bendable specialist at the St. Joe County Public Library</cite></blockquote>

              <div className="Chart">
                <h2 className="Chart-title">The learning people need, available through the places they trust</h2>
                <div className="Chart-wrap">
                  <div className="Chart-image">
                      <img width="410" height="410" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/pie-chart.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/pie-chart.png 410w, https://bendable.s3.us-west-1.amazonaws.com/marketing/pie-chart-300x300.png 300w, https://bendable.s3.us-west-1.amazonaws.com/marketing/pie-chart-150x150.png 150w" sizes="(max-width: 410px) 100vw, 410px" />
                  </div>
                  <div className="Chart-content mobile-slider">
                    <div className="Chart-item item1">
                      <div>
                        <h3>Customized</h3>
                        <p>Thousands of resources on each Bendable site are curated to meet the learning needs of a particular group of users at a specific location, whether they are library patrons, K-12 parents at a school’s family resource center, residents of a senior living community or supportive housing, or participants in a job center, youth center or community center program.</p>
                      </div>
                    </div>
                    <div className="Chart-item item2">
                      <div>
                        <h3>Community-oriented</h3>
                        <p>Running an in-person learning workshop is a breeze with Bendable’s step-by-step guides. They’ll explain how to access a particular video or short reading on Bendable, and then lead a discussion with participants on the topic.</p>
                      </div>
                    </div>
                    <div className="Chart-item item3">
                      <div>
                        <h3>Convenient</h3>
                        <p>It’s easy for any location to become a learning center with our posters, table-top signs and quick-guides for people to sign up and start using Bendable. The Bendable team will train local staff to implement the program, as well as provide ongoing technical support and insights reports to keep things humming.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>

            <Grid
              container
              direction={{ xs: 'column', lg: 'row'}}
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%',
                height: '100%',
                background: '#ffffff',
              }}
            >
              <Grid 
                item 
                xs={12} 
                sm={6}
                sx={{
                  height: { xs: '250px', lg: '750px'},
                  width: '100%'
                }}
              >
                <ContactFormImageBG />
              </Grid>        
              
              <Grid
                item
                id="contactFormWrapper" 
                xs={12} 
                sm={6}
                sx={{
                  width: '100%',
                  height: { xs: '100%', lg: '750px'},
                  overflow: 'hidden'
                }}
              >
                <ContactForm
                  backgroundColor="#1B7392"
                  showTopLine={false} 
                />
              </Grid>
            </Grid>


          </div>

        </article> */}

      </main>


      <footer id="colophon" className="Site-footer">

        <BendableLabsLogo
          width = {{ xs: '150px', sm: '200px', md: '200px', lg: '200px', xl: '250px' }}
        />
        
        {/* <div className="Site-info">
          <div className="Site-logos">
            <a href="/" className="custom-logo-link" rel="home" aria-current="page"><img width="236" height="74" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/bendable-logo.svg" className="custom-logo" alt="Bendable" /></a>  
            <a href="https://www.drucker.institute/" target="_blank" className="drucker-logo">
              <img width="461" height="264" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/drucker-logo.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/drucker-logo.png 461w, https://bendable.s3.us-west-1.amazonaws.com/marketing/drucker-logo-300x172.png 300w" sizes="(max-width: 461px) 100vw, 461px" />  	
            </a>
          </div>
        </div> */}
        
        {/* <nav id="site-navigation" className="Site-navigation">
          <div className="menu-menu-container">
            <ul id="footer-menu" className="menu">
              <li id="menu-item-200" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-200"><a href="https://drucker.institute/programs/city-of-lifelong-learning/">Network</a></li>
              <li id="menu-item-201" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-201"><a href="mailto:support@bendable.com">Tech Support</a></li>
              <li id="menu-item-202" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-202"><a href="https://network.bendable.com/privacy-policy">Privacy Policy</a></li>
              <li id="menu-item-203" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-203"><a href="https://network.bendable.com/terms-of-service">Terms of Service</a></li>
            </ul>
          </div>		
        </nav> */}
        
      </footer>

    </div>

  </Layout>
  )
}

export default IndexPage;