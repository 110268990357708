import React, {useContext} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const QuoteBlock = (props) => {
  const {
    sx,
  } = props;

  return (
    <Box
      id="quote_block"
      sx={{
        width: '100%',
        padding: { xs: '20px', sm: '20px', lg: '20px' },
        paddingTop: { md: '40px', lg: '40px' },
        paddingRight: { md: '40px', lg: '0px' },
        height: { xs: '340px', sm: '420px', md: '500px', lg: '100%' },
        minHeight: { xs: '450px', sm: '560px', md: '500px', lg: '550px', xl: '450px' },
        backgroundColor: '#EC7F77',
        ...sx
      }}
    >
      <Stack 
        direction="row" 
        sx={{
          marginLeft: { xs: '10px', sm: '20px', md: '30px' },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Cooper Std',
            color: '#ffffff',
            marginTop: { xs: '40px', sm: '57px', md: '36px', lg: '50px' },
            marginBottom: { xs: '10px', sm: '15px', lg: '20px' },
            fontSize: { xs: '26px', sm: '45px', md: '40px', lg: '52px' },
            lineHeight: { xs: '26px', sm: '42px', md: '40px', lg: '50px' },
            padding: { xs: '15px', sm: '20px', md: '25px' },
            paddingRight: { xs: '15px', sm: '20px', md: '25px', lg: '40px' },
          }}
        >
          “The key to any successful program is information. But unfortunately, the 
          folks who need the information the most often don't get it in a usable, 
          friendly way. Bendable is what's missing.”
        </Typography>
      </Stack>
       

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontWeight: 'bold',
          color: '#ffffff',
          marginTop: { xs: '20px', sm: '30px', md: '40px' },
          marginLeft: { xs: '10px', sm: '20px', md: '30px' },
          fontSize: { xs: '20px', sm: '30px', md: '36px' },
          lineHeight: { xs: '18px', sm: '30px', md: '34px' },
          paddingLeft: { xs: '15px', sm: '20px', md: '25px' },
          paddingBottom: { xs: '10px', sm: '15px', md: '20px' },
        }}
      >
        &mdash; Robert Sainz
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          color: '#ffffff',
          // marginTop: { xs: '20px', sm: '20px', md: '20px' },
          marginLeft: { xs: '10px', sm: '20px', md: '30px' },
          fontSize: { xs: '16px', sm: '24px', md: '24px' },
          lineHeight: { xs: '18px', sm: '30px', md: '34px' },
          paddingLeft: { xs: '15px', sm: '20px', md: '25px' },
        }}
      >
        Executive director of New Ways to Work
      </Typography>

    </Box>
  );
};

export default QuoteBlock;
