import React from 'react';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import ContactFormImageBG2 from './ContactFormImageBG2';
import ContactForm from '../librariesPage/contactForm';

const ContactFormBlock = (props) => {

  return (
    <Grid
      container
      direction={{ xs: 'column', lg: 'row'}}
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%',
        background: '#ffffff',
      }}
    >
      <Grid
        item
        id="contactFormWrapper" 
        xs={12} 
        sm={6}
        sx={{
          width: '100%',
          height: { xs: '100%', lg: '750px'},
          overflow: 'hidden'
        }}
      >
        <ContactForm
          backgroundColor="#1B7392"
          showTopLine={false} 
        />
      </Grid>

      <Grid 
        item 
        xs={12} 
        sm={6}
        sx={{
          height: { xs: '250px', md: '350px', lg: '750px'},
          width: '100%'
        }}
      >
        <ContactFormImageBG2 />
      </Grid>
    </Grid>
  );
};

export default ContactFormBlock;