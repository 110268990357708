import React from 'react';

import Box from '@mui/material/Box';

import backgroundImage from '../../images/contact_form_bg_2.jpg';

const ContactFormImageBG2 = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '365px', sm: '460px', md: '610px', lg: '100%' },
        background: `url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      &nbsp;
    </Box>
  );
};

export default ContactFormImageBG2;