import React from 'react';

import Box from '@mui/material/Box';

import HeroImageWoman from '../../images/bendable_phones_Dexter.png';

const HeroImageBendablePhones = (props) => {
  const {
    imageUrl = HeroImageWoman,        // default hero image
    sx,
  } = props;

  return (
    <Box
      id="bendable-labs-hero-wrapper"
      sx={{
        width: '100%',
        height: { xs: '380px', sm: '380px', md: '380px', lg: '380px', xl: '420px' },
        background: `url(${imageUrl}) no-repeat center center`,
        backgroundSize: 'cover',
        ...sx
      }}
    >
      &nbsp;
    </Box>
  );
};

export default HeroImageBendablePhones;
